/*.........top-header----------*/
body {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  line-height: 1.57143;
  font-weight: 400;
  /* color: #2d2e2e;*/
}

.drop-down-st {
  color: #100e0e !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgb(130 138 145 / 0%) !important;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgb(130 138 145 / 0%) !important;
}

.nav-header-top {
  background-color: #141313;
  padding-top: 10px;
}

.top-left-item {
  display: inline-flex;
  justify-content: space-evenly;
  padding-inline-start: 10px;
}

.top-item-link a {
  color: #fff;
  text-decoration: none;
  padding-right: 10px;
}

.top-item-link {
  list-style-type: none;
}

.header-top-right {
  text-align: right;
}

.right-soical-icon {
  color: #fff;
  padding: 10px;
}

.top-item-link a:hover {
  color: #ff1c00;
}

.right-soical-icon i:hover {
  color: #ff1c00;
}

.service-sidebar ul {
  padding-left: 0px;
}

li {
  list-style-type: none;
}

a {
  color: #000000;
  text-decoration: none;
  outline: none;
}

/*
a:hover {
  color: #fff;
} */


a:focus {
  color: #fff;
  text-decoration: none;
  outline: none;
}

button {
  outline: none;
}

button:hover,
button:focus,
button:active {
  outline: none !important;
}

p {
  margin-bottom: 35px;
  line-height: 28px;
}

input,
textarea {
  border: 2px solid #e4edee;
  border-radius: 5px;
  padding: 15px;
  outline: none;
}

input:focus,
textarea:focus {
  border: 2px solid #00dba5;
}

frame {
  border: none;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

.mobile-nav {
  display: none;
}

.logo img {
  width: 100%;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: unset;
  align-items: center;
}

ul.navbar-nav {
  padding-left: 110px;
}

.navbar-nav nav ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.navbar-nav li a {
  color: #000 !important;
  font-weight: 400;
  font-size: 17px;
}

.navbar-nav li a.nav-link.active {
  color: #f33e1d !important;
}

.navbar-nav li a:hover {
  color: #f33e1d !important;
}

.footer-item li:hover {
  color: red;
}

.footer-item {
  padding-left: 18px;
}

body,
html {
  height: 100%;
  margin: 0;
}

.custom-control {
  position: absolute;
  top: 2em;
  right: 2em;
  z-index: 999;

  button {
    background: deepskyblue;
    border: 1px solid;
    color: white;
    font-size: 1em;
    padding: 0.2em 0.6em;
    outline: none;

    &.active {
      background: #7cd279;
    }
  }
}

.owl-carousel {
  position: relative;
  height: 100%;

  .owl-slide {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .owl-controls {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;

    .owl-buttons {
      div {
        position: absolute;
        top: 0;
        bottom: 0;
        display: inline-block;
        zoom: 1;
        margin: 0;
        width: 50px;
        height: 50px;
        line-height: 45px;
        text-align: center;
        font-size: 1em;
        border-radius: 30px;
        color: #fff;
        background: #000;
        opacity: 0.6;
        border-radius: 50px;
      }

      .owl-prev {
        left: 0.5em;
      }

      .owl-next {
        right: 0.5em;
      }
    }
  }
}

/*------query-form css----------*/
.checkbox-bg {
  background: #e7e7e77d;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 10px;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0;
}

.checkbox-bg img {
  width: 32px;
}

.checkbox-bg label {
  font-size: 11px;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0;
}

.form-query {
  background-color: #fff;
  padding: 20px;
  border: 3px solid #fff;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 8px !important;
}

.request-quote-sec {

  position: relative;
  margin-top: -75px;
  overflow-x: hidden;
  padding-bottom: 40px;
}

.main-slider {
  position: relative;
}

.theme-btn.bttn {
  background-color: #f33e1d;
  color: #fff;
  padding: 11px 35px;
  border-radius: 5px;
  border: unset;
}

.form-check .form-check-input {
  float: left;
  margin-left: -21px !important;
}

/*.introduction-section {
  background-color: #f6f6f9;
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url(./images/about-b.jpeg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}*/
/*----how -to-work -------------*/
/*---hr-line-css------*/
.img-box {
  width: 23%;
  margin-top: -23px;
}

.style-hd1 {
  width: 55px;
  border: 1px solid #a51616 !important;
}

.style-hd2 {
  width: 55px;
  border: 1px solid #a51616 !important;
}

.icon-img {
  width: 35%;
  border-radius: 100%;
  border: 2px solid #f33e1d;
  padding: 20px;
}

.icon-box h4 {
  font-size: 18px;
  margin-top: 20px;
}

.icon-box p {
  margin-top: 11px;
}

.icon-size {
  width: 30px;
}

.menu-tab-sec {
  background-color: #8b807e17;
}

.tab-item:hover {
  background-color: #fff;
  color: #f33e1d;
}

.menu-tab-sec .nav-item .nav-link {
  border: 0;
  transition: .3s;
  color: #f33e1d;
  border-radius: 0;
  border-right: 2px solid #ebf1f6;
  font-weight: 600;
  font-size: 18px;
  padding: 10px 20px;
  width: 100px;
}

.list-style-three {
  list-style-type: none;
  padding-left: 0px;
}

.list-style-three li i {
  color: #f33e1d;
}

/* .introduction-section {

  background-color: #f6f6f9;
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url(./images/about-b.jpeg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
} */
.sm-headings.text-center {
  font-size: 16px;
  color: #f33e1d;
}

.why-choose-us {
  background-color: #f33e1d;
  padding-top: 20px;
  padding-bottom: 20px;
}

.service-sec {
  padding-top: 40px;
  padding-bottom: 40px;
}

.service_img {
  width: 22%;
  padding-top: 10px;
}

.card1 {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  color: #000;
}

.service_title {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 14px;
}

.service_title a {
  color: #000;
  text-decoration: none;
}

.service_item p {
  font-size: 15px;
  color: #000000cf;
}

a.ser-btn {
  color: #F44336;
  font-weight: 600;
  text-decoration: none;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #f33e1d;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card1 {
  display: block;
  position: relative;
  /* max-width: 262px;*/

  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #f33e1d;
    height: 32px;
    width: 43px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }

  &:hover:before {
    transform: scale(21);
  }
}

.card1:hover {
  p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
  }

  h4 {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
}

.card1:hover img {
  filter: brightness(10.5);
}

.ser-title {
  font-size: 20px;
  margin-top: 20px;
}

.sec-client {
  padding-top: 40px;
  padding-bottom: 50px;

  background-color: #0b0c26;
}

.col-carousel {
  margin: 70px 0;
}

/* owl nav */
.owl-prev span,
.owl-next span {
  color: #FFF;
}

.owl-prev span:hover,
.owl-next span:hover {
  color: #8199A3;
}

.owl-prev,
.owl-next {
  position: absolute;
  top: 0;
  height: 100%;
}

.owl-prev {
  left: 7px;
}

.owl-next {
  right: 7px;
}

/* removing blue outline from buttons */
button:focus,
button:active {
  outline: none;
}

.sec-blog {
  padding-top: 50px;
  padding-bottom: 50px;
}

.card-box {
  box-sizing: content-box;
  box-shadow: 0 2px 12px rgba(44, 73, 100, .08);
  position: relative;
  background: #fff;
  margin-bottom: 25px;
}

.card {
  margin-bottom: 25px;
}

.slide {
  margin-bottom: 14px;
}

.card-img-top {
  height: 160px;
}

p.card-text {
  font-size: 20px;
  font-weight: 600;
}

.meta-info {
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
}

.meta-info li {
  color: red;
  list-style-type: none;
}

.meta-info li a {
  color: #00000061;
  text-decoration: none;
}

.footer {
  background-color: #000;
  padding-top: 10px;
  padding-bottom: 30px;
}

.text-center.text-f-p.p-1 {
  color: #fff;
}

/* .sec-branch {
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: url(./images/contact-bg.png);
  background-color: #cbbcb717;
  background-size: contain;
  background-attachment: fixed;
} */
.style-branch ul li {
  list-style-type: none;
}

.style-branch li a {
  font-size: 14px;
  color: #121010;
  text-decoration: none;
}

.style-branch ul li i {
  color: #f33e1d;
  padding-right: 2px;
}

.style-branch ul li {
  padding-bottom: 10px;
}

.style-branch li a:hover {
  color: #f33e1d;
}

.contact-info {
  background-color: #f7f4f4;
  padding-bottom: 20px;
  padding-top: 57px;
  border-top: 1px solid #f33e1d;
  border-bottom: 1px solid #f33e1d;
}

.contact-info-item i {
  color: #f33e1d;
  font-size: 30px;
}

.footer-item li {
  list-style-type: none;
}

.text-reset {
  text-decoration: none;
}

.marq-sec {
  color: #fff;
  font-size: 19px;
  font-weight: 500;
}

/*----our-client-------*/

.slide img {
  width: 100px;
}

.slide img {
  width: 100%;
}

.customer-logos.slider {
  background-color: #f1f7fd;
  padding-bottom: 50px;
  padding-top: 50px;
  box-shadow: rgb(33 150 243 / 15%) 0px 1px 0px, rgb(33 150 243 / 8%) 0px 1px 0px inset;
}

/*--------------about-page------------------------*/
/*.main-sec-about {
  background-image: url(./images/packers-movers3.jpg);
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
}*/
.count-span-icon span i {
  color: #f33e1d;
  font-size: 26px;
}

.count-box {
  color: #fff;
  font-size: 36px;
  font-weight: 600;
}

.count-box p {
  font-size: 16px;
}

.sec-vision-mision {
  padding-top: 40px;
  padding-bottom: 40px;
}

.vision-form.hd-style {
  background-color: #d1d1d10d;
  padding: 35px 20px 35px 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.btn-primary {
  color: #fff;
  background-color: #161718;
  border-color: #171718;
}

/* .main-sec-about {
  background-image: url(./images/bg-service.jpg);
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
  background-repeat: no-repeat;
} */
h2.sec-title-kl {
  color: #fff;
  font-weight: 600;
}

/*-----branches---------*/
.main-sec-client {
  padding-top: 40px;
  padding-bottom: 40px;
}

.inner-city-link {
  padding: 10px 0px;
  border: 1px solid #f33e1d00;
  margin-bottom: 10px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  background: #eef4f7;
}

.inner-city-link a {
  color: #000;
  text-decoration: none;
}

.inner-brand-link img {
  width: 100%;
  border: 1px solid #f33e1d24;
  margin: 20px;
}

.contact-info-content {
  text-align: center;
  padding: 20px;
  background-color: #80808014;
  margin: 20px;
  box-shadow: rgb(9 30 66 / 3%) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.contact-info-content a i {
  color: #f33e1d;
  font-size: 40px;
}

.contact-title {
  color: #342b2b;
  font-size: 17px;
  text-decoration: none;
}

a.contact-title:active {
  color: red;
}

a.contact-title:Hover {
  color: red;

}

.notification {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px 15px;
  margin: 0;
  text-align: center;
  z-index: 1;
  background: var(--yellow);
}

@media (max-width: 700px) {
  .notification {
    display: none;
  }
}





/* LIGHTBOX STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.lightbox-modal .modal-content {
  background: var(--lightbox);
}

.lightbox-modal .btn-close {
  position: absolute;
  top: 20px;
  right: 18px;
  font-size: 1.2rem;
  z-index: 10;
}

.lightbox-modal .modal-body {
  display: flex;
  align-items: center;
  padding: 0;
  text-align: center;
}

.lightbox-modal img {
  width: auto;
  max-height: 100vh;
  max-width: 100%;
}

.lightbox-modal .carousel-caption {
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(36, 36, 36, 0.75);
}

.lightbox-modal .carousel-control-prev,
.lightbox-modal .carousel-control-next {
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  width: auto;
}

.lightbox-modal .carousel-control-prev {
  left: 10px;
}

.lightbox-modal .carousel-control-next {
  right: 10px;
}


/* FOOTER STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  position: fixed;
  right: 0;
  bottom: 60px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 5px;
  background: rgba(255, 255, 255, 0.65);
}

.page-footer a {
  display: flex;
  margin-left: 9px;
}

.image-grid {
  padding-top: 50px;
  padding-bottom: 50px;
}

.service-sidebar {
  background-color: #fff;
  padding: 35px 20px 35px 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.service-sidebar ul li a {
  background-color: #f9f8fb;
  padding: 15px 20px 15px 50px;
  border-radius: 5px;
  -webkit-transition: all .7s;
  transition: all .7s;
  position: relative;
  color: #301d44;
  display: block;
}

.service-sidebar li {
  padding: 10px;
  background-color: #a5a0a029;
  margin: 15px;
}

.service-sidebar i {
  margin-right: 10px;
}

/*.service-sidebar li:hover {
  padding: 10px;
  background-color: #f33e1d;
  margin: 15px;
}*/
.service-sidebar i:hover {
  color: #fff;
}

.service-sidebar ul li a:hover,
.service-sidebar ul li a.active {
  background-color: #f33e1d;
  color: #fff;

}

.service-sidebar a {
  text-decoration: none;
}

.sec-packers-content {
  padding-bottom: 50px;
}

img.packer-img {
  width: 100%;
}

.testimonial-item-content {
  box-sizing: content-box;
  padding: 28px 28px 28px 60px;
  margin: 30px 15px;
  box-shadow: 0 2px 12px rgba(44, 73, 100, .08);
  position: relative;
  background: #fff;
  border-radius: 20px;
}

.testimonial-item-content h4 {
  color: #999999bd;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.testimonial-item-content h3 {
  font-size: 20px;
}

.testimonial-item-content i {
  color: #ff8f00;
  font-size: 13px;
}

.bg-service-inner {
  background-color: #000000;
  padding-bottom: 50px;
  padding-top: 50px;
  box-shadow: rgb(231 50 7 / 77%) 0px 1px 0px, rgb(195 75 15) 0px 1px 0px inset;
}

.service-inner-images img {
  border-left: 2px solid #f33e1d;
  padding: 10px;
  box-shadow: 6px 4px 12px 0px;
  border-top: 2px solid #f33e1d;
  border-radius: 20px;
  margin-top: 20px;
}

.service-inner-images img {
  width: 100%;
}

.service-strech-sec {
  padding-top: 50px;
  padding-bottom: 50px;
}

/* .service-strech-sec {
  padding-top: 60px;
  padding-bottom: 60px;
  background-size: contain;
  background-image: url(./images/bg-s.jpg), linear-gradient(rgb(0 0 0 / 78%),rgb(12 1 1 / 86%));
  background-blend-mode: overlay;
} */
.call-to-action-content {
  background-color: #fff;
  padding: 20px;
}

.entry-header h1 {
  font-size: 30px;
}

.inner-content-blog p {
  text-align: justify;
}

.sec-single-page {
  margin-top: 40px;
  margin-bottom: 40px;
}

.inner-content-blog p {
  text-align: justify;
  padding-top: 15px;
}

.hire-packer h5 {
  text-align: center;
  color: #f33e1d;
  margin-bottom: 20px;
  margin-top: 20px;
}

.right-content-blog {
  text-align: center;
  padding: 25px;
}

.left-content-blog {
  border-right: 1px solid #00000033;
  padding-right: 50px;
}

.nav-links {
  display: flex;
  justify-content: space-between;
}

.nav-previous a {
  color: #0f0f44;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
}

.nav-previous a:hover {
  color: #f33e1d;

}

.nav-next a {
  color: #0f0f44;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
}

.nav-next a:hover {
  color: #f33e1d;

}

@media only screen and (max-width: 768px) {
  .header-top-right {
    text-align: center;
    padding-bottom: 10px;
  }

  ul.top-left-item {
    padding-left: 0px;
  }

  .top-item-link a {

    font-size: 14px;
  }

  .nav-header-top {
    background-color: #141313;
    padding-top: 15px;
    padding-bottom: 8px;
  }

  .left-content-blog {
    border-right: unset;
    padding-right: 0px;
  }
}

.kn-btn {
  font-size: 21px;
  background: rgb(25, 135, 84);
  color: #fff;
  padding: 11px 50px;
  border-radius: 50px;
  position: absolute;
  top: 547px;
  right: 35px;
  cursor: pointer;
}